import { Component, OnInit } from '@angular/core';
import { HttpclientService } from 'src/app/modules/application/httpclient/httpclient.service';
import { environment } from '../../../../../../src/environments/environment';
import { DomSanitizer, SafeResourceUrl } from '@angular/platform-browser';

@Component({
  selector: 'app-resources-spotlight',
  templateUrl: './resources-spotlight.component.html',
  styleUrls: ['./resources-spotlight.component.scss']
})
export class ResourcesSpotlightComponent implements OnInit {
  loaded: boolean;
  spotLightDatas: any;
  envSiteName:any;
  GlobalRessourcesADAfterSpotlight:any;
  GlobalArticlesADAfterSpotlight:any;
  GlobalVideosADAfterSpotlight:any;
  GlobalWhitepapersADAfterSpotlight:any;
  GlobalInfographicsADAfterSpotlight:any;
  
  resoAD:string='https://'+environment.siteName+'/ADcodeFile/GlobalRessourcesADAfterSpotlight.html'
  artiAD:string='https://'+environment.siteName+'/ADcodeFile/GlobalArticlesADAfterSpotlight.html'
  videAD:string='https://'+environment.siteName+'/ADcodeFile/GlobalVideosADAfterSpotlight.html'
  whitAD:string='https://'+environment.siteName+'/ADcodeFile/GlobalWhitepapersADAfterSpotlight.html'
  infoAD:string='https://'+environment.siteName+'/ADcodeFile/GlobalInfographicsADAfterSpotlight.html'

  reso:String= "https://"+window.location.hostname+"/resources";
  arti:String= "https://"+window.location.hostname+"/resources/articles";
  vide:string = "https://"+window.location.hostname+"/resources/videos";
  whit:String= "https://"+window.location.hostname+"/resources/whitepapers";
  info:string = "https://"+window.location.hostname+"/resources/infographics";
  currentpageeurl:string=window.location.href;

  constructor(private httpclientService: HttpclientService, private sanitizer:DomSanitizer) {
    this.envSiteName=environment.siteName;
   }

  ngOnInit(): void {
    this.spotLightData()
    this.GlobalRessourcesADAfterSpotlight=this.sanitizer.bypassSecurityTrustResourceUrl(this.resoAD);
    this.GlobalArticlesADAfterSpotlight=this.sanitizer.bypassSecurityTrustResourceUrl(this.artiAD);
    this.GlobalVideosADAfterSpotlight=this.sanitizer.bypassSecurityTrustResourceUrl(this.videAD);
    this.GlobalWhitepapersADAfterSpotlight=this.sanitizer.bypassSecurityTrustResourceUrl(this.whitAD);
    this.GlobalInfographicsADAfterSpotlight=this.sanitizer.bypassSecurityTrustResourceUrl(this.infoAD);
    this.ResourcesAD();
  }

  ResourcesAD(): void{

    // alert(this.fnurl);
    if(this.arti == window.location.href){
       document.getElementById('artspo').classList.add('displayblock');
    }else if(this.vide == window.location.href){
      document.getElementById('vidspo').classList.add('displayblock'); 
    }else if(this.whit == window.location.href){
      document.getElementById('whispo').classList.add('displayblock'); 
    }else if(this.info == window.location.href){
      document.getElementById('infospo').classList.add('displayblock'); 
    }else{
      document.getElementById('resospo').classList.add('displayblock'); 
     }
  }
  
  spotLightData(): void
  {
    this.loaded =false;
    this.httpclientService.getHttpclient('https://newapi.nathanark.com/api/GlobalReources/GetTop1GlobalReourcesSpotLight?siteName='+this.envSiteName)
    .subscribe(
      items => {
        this.spotLightDatas = items[0];
        this.loaded = true;
        console.log("SpotLight Resorces Data:", this.spotLightDatas);
      }
    );
  }
}
