<div class="row">
    <div class="text-center add-center displaynone" id="event">
        <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalEventTopAD"></iframe>
    </div>
    <!-- <div class="text-center add-center displaynone" id="upconferenceTop">
        <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalUpcomingConferencesTopAD"></iframe>
    </div>
    <div class="text-center add-center displaynone" id="psconferenceTop">
        <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalPastConferencesTopAD"></iframe>
    </div>
    <div class="text-center add-center displaynone" id="liwebinarTop">
        <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalLiveWebinarTopAD"></iframe>
    </div>
    <div class="text-center add-center displaynone" id="ondewebinarTop">
        <iframe style='min-width: 743px; max-height:110px; border: none;' class="HideSliderMobile" ng-include [src]="GlobalOndemandWebinarTopAD"></iframe>
    </div> -->
    <div class="col-md-9">
        <router-outlet></router-outlet>
    </div>
    <div class="col-md-3">
        <app-spotlight-events></app-spotlight-events>
        <div class="text-center ADdiv displaynone" id="eventSpo" style="display:flex; justify-content:center; align-items:center">
            <iframe scrolling="none" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="GlobalEventADAfterSpotlight"></iframe>
        </div>
        <!-- <div class="text-center ADdiv displaynone" id="upconferenceSpo" style="display:flex; justify-content:center; align-items:center">
            <iframe scrolling="none" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="GlobalUpcomingConferencesADAfterSpotlight"></iframe>
        </div>
        <div class="text-center ADdiv displaynone" id="psconferenceSpo" style="display:flex; justify-content:center; align-items:center">
            <iframe scrolling="none" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="GlobalPastConferencesADAfterSpotlight"></iframe>
        </div>
        <div class="text-center ADdiv displaynone" id="liwebinarSpo" style="display:flex; justify-content:center; align-items:center">
            <iframe scrolling="none" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="GlobalLiveWebinarADAfterSpotlight"></iframe>
        </div>
        <div class="text-center ADdiv displaynone" id="ondewebinarSpo" style="display:flex; justify-content:center; align-items:center">
            <iframe scrolling="none" frameborder="0" marginwidth="0" marginheight="0" style='min-height:266px; border: none;' ng-include [src]="GlobalOndemandWebinarADAfterSpotlight"></iframe>
        </div> -->
        <app-square-ad-events></app-square-ad-events>
        <app-resources-events></app-resources-events>
    </div>
</div>